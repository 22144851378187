<script setup lang="ts">
import type { Schemas } from "#shopware";
const { apiClient } = useShopwareContext();
const products = ref<Schemas["Product"][]>([]);
const isLoading = ref(false);
const { items } = useLastSeenList();

const loadProductsByItemIds = async (itemIds: string[]): Promise<void> => {
  isLoading.value = true;

  try {
    const { data: result } = await apiClient.invoke(
      "readProduct post /product",
      {
        body: { ids: itemIds || items.value },
      },
    );

    if (result?.elements) {
      products.value = result.elements;
    }
  } catch (error) {
    console.error("[lastSeenList][loadProductsByItemIds]", error);
  }

  isLoading.value = false;
};

watch(
  items,
  (items, oldItems) => {
    if (items.length !== oldItems?.length) {
      products.value = products.value.filter(({ id }) => items.includes(id));
    }
    if (!items.length) {
      return;
    }
    loadProductsByItemIds(items);
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <ProductSlider
    v-if="products.length"
    class="custom-container"
    :products="products"
    :title="$t('lastSeenList.lastSeen')"
  />
</template>
